import { GatsbyImage as Img } from 'gatsby-plugin-image';
import styled from 'styled-components';

const TrustCard = styled.div<{ seoPage?: boolean }>`
  align-items: center;
  display: flex;
  padding: 24px;

  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  display: none !important;

  ${(mediaQuery) => mediaQuery.theme.forTabletVerticalUp} {
    justify-content: center;
  }

  ${(mediaQuery) => mediaQuery.theme.forTabletHorizontalUp} {
    padding: ${({ seoPage }) => (seoPage ? '40px' : '24px')};
  }
`;

const Icon = styled(Img)`
  /* TODO: check if color is correct, previous color "everlyDark" did not exist */
  border: solid 1px rgb(${(props) => props.theme.colors.oceanGreen});
  border-radius: 50%;
  height: 48px;
  width: 48px;
`;

const IconWrapper = styled.div`
  margin-right: 16px;
`;

const Content = styled.div`
  color: rgb(${(props) => props.theme.colors.mineShaft});
  font-size: 14px;
  line-height: 1.71;

  ${(mediaQuery) => mediaQuery.theme.forTabletVerticalUp} {
    &,
    p {
      display: inline-block;
    }
    p {
      margin-right: 10px;
    }
  }
`;

export { Content, TrustCard, IconWrapper, Icon };
