import { typography } from '@everlywell/leaves';
import styled, { css } from 'styled-components';

const Section = styled.div<{ bgColor: string }>`
  background: ${({ bgColor, theme }) => bgColor || `rgb(${theme.white})`};
  padding-bottom: 4px;

  ${(mediaQuery) => mediaQuery.theme.forDesktopUp} {
    padding-bottom: 36px;
  }
`;

const ImageWrap = styled.div`
  max-width: 1147px;
  margin: 0 auto;

  .gatsby-image-wrapper {
    max-height: 600px;
  }
`;

const TextWrapper = styled.div<{ isProductSection?: boolean }>`
  padding: 24px;
  position: relative;

  ${(mediaQuery) => mediaQuery.theme.forTabletHorizontalUp} {
    padding: 24px 112px 0;
  }

  ${(mediaQuery) => mediaQuery.theme.forDesktopUp} {
    padding: 24px 264px 0;
  }

  ${({ isProductSection }) =>
    isProductSection &&
    css`
      padding: 24px 0 0;
      text-align: center;

      ${(mediaQuery) => mediaQuery.theme.forTabletVerticalUp} {
        padding: 24px 0 0;
      }

      ${(mediaQuery) => mediaQuery.theme.forTabletHorizontalUp} {
        padding: 24px 0 0;
      }

      ${(mediaQuery) => mediaQuery.theme.forDesktopUp} {
        padding: 24px 0 0;
      }
      ${(mediaQuery) => mediaQuery.theme.forBigDesktopUp} {
        padding: 24px 264px 0;
      }
    `}
`;

const Title = styled.h2<{ isReferencesSection: boolean }>`
  ${({ isReferencesSection }) =>
    isReferencesSection &&
    `
    font-size: 18px;
    line-height: 1.78;
  `}
  margin-bottom: ${({ isReferencesSection }) =>
    isReferencesSection ? '10px' : '4px'};

  ${(mediaQuery) => mediaQuery.theme.forTabletVerticalUp} {
    font-size: ${({ isReferencesSection }) =>
      isReferencesSection ? '18' : '32.4'}px;
    letter-spacing: 0.1px;
    line-height: ${({ isReferencesSection }) =>
      isReferencesSection ? '1.78' : '1.48'};
    margin-bottom: ${({ isReferencesSection }) =>
      isReferencesSection ? '10px' : '8px'};
  }
`;

const Subtitle = styled.h3`
  font-size: 18px;
  line-height: 1.88;

  ${(mediaQuery) => mediaQuery.theme.forTabletVerticalUp} {
    font-size: 22.8px;
    line-height: 1.4;
  }
`;

const baseContentStyles = css<{ isProductSection?: boolean }>`
  line-height: 1.88;
  margin-bottom: 24px;
  text-align: left;
  ${({ isProductSection, theme }) =>
    isProductSection &&
    `
    padding: 0 24px;

    ${theme.forTabletHorizontalUp} {
      padding: 0 112px;
    }
  `};

  p {
    margin-bottom: 16px;
    font-weight: ${typography.weight.light};

    ${(mediaQuery) => mediaQuery.theme.forTabletHorizontalUp} {
      margin-bottom: 24px;
    }
  }

  a {
    color: rgb(${({ theme }) => theme.linkColor});
    text-decoration: none;
  }

  sup {
    white-space: nowrap;
    font-size: 12px;
  }

  img {
    width: 48px;
  }

  li {
    margin-top: 8px;
  }

  ul {
    padding: 0 32px;
  }
  ${(mediaQuery) => mediaQuery.theme.forTabletVerticalUp} {
    line-height: 1.78;
    font-size: 18px;
    margin-bottom: 32px;

    ul {
      line-height: 1.78;
    }
  }
`;

const referencesContentStyles = css`
  color: rgb(${({ theme }) => theme.quaternaryTextColor});
  font-size: 14px;
  line-height: 1.57;
  margin: 0 0 24px;

  li {
    padding-left: 0.7rem;

    p {
      margin-bottom: 0.7rem;
    }
  }

  ol {
    padding-inline-start: 1.7rem;
  }

  ${(mediaQuery) => mediaQuery.theme.forTabletVerticalUp} {
    font-size: 14px;
    margin: 0 0 32px;
  }
`;

const productContentStyles = css`
  padding: 0 24px;

  ${(mediaQuery) => mediaQuery.theme.forTabletHorizontalUp} {
    padding: 0 112px;
  }
`;

interface ContentProps {
  isReferencesSection?: boolean;
  isProductSection?: boolean;
}
const Content = styled.div<ContentProps>`
  ${baseContentStyles}
  ${({ isReferencesSection }) =>
    isReferencesSection && referencesContentStyles};
  ${({ isProductSection }) => isProductSection && productContentStyles};
`;

const ProductSubtitle = styled(Content)`
  font-weight: ${typography.weight.light};
  text-align: center;
`;

const AdditionalInfo = styled.div<{ isProductSection: boolean }>`
  text-align: left;
  ${({ isProductSection }) =>
    isProductSection &&
    css`
      padding: 0 24px;

      ${(mediaQuery) => mediaQuery.theme.forTabletHorizontalUp} {
        padding: 0 112px;
      }

      ${(mediaQuery) => mediaQuery.theme.forDesktopUp} {
        padding: 0 150px;
      }
    `};
`;

export {
  AdditionalInfo,
  Content,
  ImageWrap,
  Section,
  Subtitle,
  ProductSubtitle,
  TextWrapper,
  Title,
};
