import { typography } from '@everlywell/leaves';
import styled from 'styled-components';
import { linkPseudoStates } from 'styles/utils';

const Wrapper = styled.div`
  border-top: solid 2px rgb(${(props) => props.theme.defaultBorderColor});
  display: flex;
  padding-top: 14px;

  ${(mediaQuery) => mediaQuery.theme.forTabletVerticalUp} {
    align-items: center;
    padding-top: 24px;
  }
`;

const IconWrapper = styled.div`
  margin-right: 16px;
  width: 67px;
  min-width: 67px; /* no flex shriking */

  ${(mediaQuery) => mediaQuery.theme.forTabletVerticalUp} {
    margin-right: 32px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${(mediaQuery) => mediaQuery.theme.forTabletVerticalUp} {
    display: block;
  }
`;

const Content = styled.div`
  color: rgb(${(props) => props.theme.primaryTextColor});
  font-size: 16px;
  line-height: 1.71;

  p {
    font-weight: ${typography.weight.light};
  }

  a {
    font-weight: ${typography.weight.bold};
    ${linkPseudoStates}
  }

  ${(mediaQuery) => mediaQuery.theme.forTabletVerticalUp} {
    &,
    p {
      display: inline-block;
    }

    p {
      margin-right: 10px;
    }
  }
`;

const Link = styled.a`
  font-size: 14.2px;
  line-height: 1.69;
  letter-spacing: 1px;
  margin-top: 3px;
  text-decoration: none;
  font-weight: ${typography.weight.bold};
  ${linkPseudoStates}
`;

export { Content, ContentWrapper, Wrapper, IconWrapper, Link };
