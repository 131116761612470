import React from 'react';
import ReactDOM from 'react-dom';
import { MODAL as dataTest } from 'utils/constants/dataTest';
import { useClickOff, useDisableBodyScroll } from 'utils/helpers';

import * as S from './styles';

type ModalPortalProps = {
  children: JSX.Element | JSX.Element[];
};

const ModalPortal = (props: ModalPortalProps) => {
  const { children } = props;
  const modalEl = document.getElementById('modal');

  return modalEl ? (
    ReactDOM.createPortal(children, modalEl)
  ) : (
    <div>{children}</div>
  );
};

type Props = {
  onClose: (e: any) => void;
  children: JSX.Element | JSX.Element[];
};

const Modal = (props: Props) => {
  const { children, onClose } = props;
  const contentEl = useClickOff(onClose);
  const innerContentWrapperEl = useDisableBodyScroll();

  return (
    <ModalPortal>
      <S.Wrapper>
        <S.Content data-testid={dataTest.CONTENT} ref={contentEl}>
          <S.CloseButton
            data-testid={dataTest.CLOSE_BUTTON}
            onClick={onClose}
          />
          <S.InnerContent ref={innerContentWrapperEl}>
            {children}
          </S.InnerContent>
        </S.Content>
      </S.Wrapper>
    </ModalPortal>
  );
};

export default Modal;
