import { GatsbyImage as Img } from 'gatsby-plugin-image';
import React from 'react';

import { scrollToId } from 'utils/helpers';
import { SummaryType } from 'utils/types';
import * as S from './styles';

type Props = {
  summary: SummaryType;
};

const Summary = (props: Props): JSX.Element => {
  const { summary } = props;

  return (
    <S.Summary data-testid="summary">
      <S.Content>
        {summary.summaryText}
        <S.IconWrap
          data-testid="icon-wrap"
          onClick={() => {
            scrollToId('recommendations');
          }}
        >
          {/* {summary.returnIcon ? (
            <Img image={summary.returnIcon} alt="" />
          ) : null} */}
        </S.IconWrap>
        <S.ReturnText>{summary.returnText}</S.ReturnText>
      </S.Content>
    </S.Summary>
  );
};

export default Summary;
