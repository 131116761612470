import React from 'react';
import { ProductInfoMap, SectionType } from 'utils/types';

import InfoSection from './InfoSection';
import ProductSection from './ProductSection';

type Props = {
  section: SectionType;
  productInfoMap: ProductInfoMap;
};
type SectionOpts = {
  section: SectionType;
  productInfoMap?: ProductInfoMap;
};

const Section = (props: Props): JSX.Element => {
  const { productInfoMap, section } = props;

  let Component;
  const opts: SectionOpts = { section: section };

  if (section.recommendedProducts) {
    return (
      <ProductSection
        data-testid="section"
        productInfoMap={productInfoMap as ProductInfoMap}
        section={section as SectionType}
      />
    );
  } else {
    Component = InfoSection;
  }

  // @ts-ignore-next-line productInfoMap is assigned to ProductSection opts
  return <Component data-testid="section" {...opts} />;
};

export default Section;
