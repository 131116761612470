/**
 * ProductCard
 */

import React, { useState, useRef, useEffect } from 'react';
import Carousel from 'nuka-carousel';

import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { ProductInfoMap, SuggestedProduct } from 'utils/types';
import carouselConfig, {
  CarouselConfigProps,
} from 'utils/constants/carouselConfig';
import Dots from './Dots';
import ProductCard from 'components/ProductCard';
import * as S from './styles';

type Props = {
  products: SuggestedProduct[];
  productInfoMap: ProductInfoMap;
};

const ProductCards = ({ products, productInfoMap }: Props) => {
  const [viewed, setViewed] = useState<{ [key: number]: boolean }>({});

  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    const focusedTest = currentSlide + 1;
    if (!viewed[focusedTest]) {
      ewAnalytics.track({
        data: {
          label: `Quiz - Test ${focusedTest}`,
        },
        event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      });
    }
    setViewed({ ...viewed, [focusedTest]: true });
  }, [currentSlide]);
  const [isDraggingCard, setIsDraggingCard] = useState(false);

  const cardRef = useRef(null);

  // functions for carousel
  const beforeSlide = (index: number, nextIndex: number) => {
    setCurrentSlide(nextIndex);
  };

  const controls = ({ goToSlide }: { goToSlide: Function }) => (
    <Dots
      cardCount={products.length}
      slidesToScroll={1}
      goToSlide={goToSlide}
      currentSlide={currentSlide}
    />
  );

  const onDragStart = () => {
    const sliderDiv: HTMLElement | null = document.querySelector(
      '.slider-frame',
    );

    setIsDraggingCard(true);

    if (!sliderDiv) return;

    // nuka-carousel doesn't have onDragEnd, so here's a hacky equivalent
    const onDragEnd = () => {
      setIsDraggingCard(false);
    };
    sliderDiv.ontouchend = onDragEnd;
    sliderDiv.onmouseup = onDragEnd;
  };

  const carouselOpts: CarouselConfigProps = {
    beforeSlide,
    controls,
    slideCount: products.length,
    onDragStart,
  };

  // set the width of the list in order to center the suggested products
  const sliderListWidth = 370 * products.length + 24 * products.length;

  return (
    <S.CarouselWrapper
      ref={cardRef}
      isDraggingCard={isDraggingCard}
      sliderListWidth={sliderListWidth}
    >
      <Carousel {...carouselConfig(carouselOpts)}>
        {products.length
          ? products.map((product: SuggestedProduct) => (
              <ProductCard
                key={product.productId}
                product={product}
                productInfoMap={productInfoMap}
              />
            ))
          : null}
      </Carousel>
    </S.CarouselWrapper>
  );
};
export default ProductCards;
