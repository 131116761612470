import FunFact from 'components/FunFact';
import ProductCards from 'components/ProductCards';
import RichTextWrapper from 'components/RichTextWrapper';
import TrustCard from 'components/TrustCard';
import React from 'react';
import { ProductInfoMap, SectionType } from 'utils/types';

import * as S from './styles';

type Props = {
  section: SectionType;
  productInfoMap: ProductInfoMap;
};

const ProductSection = (props: Props): JSX.Element => {
  const {
    productInfoMap,
    section: {
      title,
      subtitle,
      content,
      backgroundColor,
      recommendedProducts,
      funFact,
    },
  } = props;

  return (
    <S.Section
      data-testid="product-section"
      key={title}
      id={'recommendations'}
      bgColor={backgroundColor}
    >
      <S.TextWrapper isProductSection>
        {subtitle ? (
          <S.ProductSubtitle isProductSection>
            <RichTextWrapper contentfulRichText={subtitle} />
          </S.ProductSubtitle>
        ) : null}
        {content ? (
          <S.Content isProductSection>
            <RichTextWrapper contentfulRichText={content} />
          </S.Content>
        ) : null}
        {recommendedProducts ? (
          <ProductCards
            products={recommendedProducts}
            productInfoMap={productInfoMap}
          />
        ) : null}
        {funFact && funFact.content ? (
          <S.AdditionalInfo isProductSection>
            <FunFact {...funFact} />
          </S.AdditionalInfo>
        ) : null}
        {funFact && funFact.medicalReviewer ? (
          <S.AdditionalInfo isProductSection>
            <TrustCard {...funFact} />
          </S.AdditionalInfo>
        ) : null}
      </S.TextWrapper>
    </S.Section>
  );
};

export default ProductSection;
