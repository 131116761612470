import React from 'react';
import { AdditionalInfoType } from 'utils/types';

import * as S from './styles';

const TrustCard = (props: AdditionalInfoType): JSX.Element => {
  const { icon, medicalReviewer, seoPage, linkText } = props;

  return (
    <S.TrustCard data-testid="trust-card" seoPage={seoPage}>
      {icon ? (
        <S.IconWrapper>
          <S.Icon image={icon.gatsbyImageData} alt={linkText || ''} />
        </S.IconWrapper>
      ) : null}
      <S.Content>Medically reviewed by {medicalReviewer}.</S.Content>
    </S.TrustCard>
  );
};

export default TrustCard;
