import styled, { css } from 'styled-components';

const MaskSettings = css`
  content: '';
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;

  ${(mediaQuery) => mediaQuery.theme.forTabletVerticalUp} {
    width: 60px;
  }

  ${(mediaQuery) => mediaQuery.theme.forDesktopUp} {
    width: 100px;
  }
`;

interface CarouselWrapperProps {
  isDraggingCard: boolean;
  sliderListWidth: number;
}
const CarouselWrapper = styled.div<CarouselWrapperProps>`
  &::before {
    background: linear-gradient(
      to left,
      rgba(${(props) => props.theme.tertiaryBgColor}, 0.001) 0%,
      rgb(${(props) => props.theme.tertiaryBgColor}) 100%
    );
    left: -1px;
    opacity: ${(props) => (!props.isDraggingCard ? '0' : '1')};
    transition: opacity 0.5s;
    z-index: 1;
    ${MaskSettings}
  }

  &::after {
    background: linear-gradient(
      to right,
      rgba(${(props) => props.theme.tertiaryBgColor}, 0.001) 0%,
      rgb(${(props) => props.theme.tertiaryBgColor}) 100%
    );
    right: -1px;
    ${MaskSettings}
  }

  .slider-frame {
    padding: 4px 0 !important;
  }

  .slider-slide {
    bottom: 0;
    min-height: 530px;

    ${(mediaQuery) => mediaQuery.theme.forTabletVerticalUp} {
      min-height: 480px;
    }
  }

  .slider-list {
    cursor: auto !important;

    ${(mediaQuery) => mediaQuery.theme.forTabletVerticalUp} {
      margin: 0 auto !important;
      width: ${(props) =>
        props.sliderListWidth
          ? `${props.sliderListWidth}px`
          : '100%'} !important;
    }
  }

  .slider-control-bottomcenter {
    position: relative !important;
    text-align: center;
    margin: 16px 0;
  }
`;

const DotButton = styled.button<{ active: boolean }>`
  background-color: ${({ active, theme }) =>
    active ? `rgb(${theme.colors.pineGreen})` : 'transparent'};
  border-radius: 50%;
  cursor: pointer;
  outline: 0;
  border: solid 2px rgb(${({ theme }) => theme.colors.pineGreen});
  margin: 12px;
  padding: 0;
  width: 12px;
  height: 12px;
`;

const ListItemStyles = styled.li`
  list-style-type: none;
  display: inline-block;
`;

export { CarouselWrapper, DotButton, ListItemStyles };
