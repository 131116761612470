import styled from 'styled-components';

const Summary = styled.div`
  background-color: rgb(${(props) => props.theme.tertiaryBgColor});
  padding-bottom: 0;
`;

const Content = styled.div`
  font-size: 18px;
  line-height: 1.78;
  margin: 0;
  padding: 16px 24px;
  text-align: center;

  a {
    color: rgb(${(props) => props.theme.primaryTextColor});
    text-decoration: none;
  }

  img {
    width: 48px;
  }

  li {
    margin-top: 8px;
  }

  ul {
    padding: 0 32px;
  }

  ${(mediaQuery) => mediaQuery.theme.forTabletVerticalUp} {
    font-size: 18px;
    line-height: 1.78;
    margin: 0;
    padding: 32px 120px;

    ul {
      line-height: 1.78;
    }
  }

  ${(mediaQuery) => mediaQuery.theme.forTabletHorizontalUp} {
    padding: 32px 72px;
  }

  ${(mediaQuery) => mediaQuery.theme.forDesktopUp} {
    padding: 32px 280px;
  }
`;

const IconWrap = styled.div`
  cursor: pointer;
  height: 48px;
  margin: 24px auto 8px;
  width: 48px;
`;

const ReturnText = styled.p`
  font-size: 14px;
  line-height: 1.71;
`;

export { Content, IconWrap, ReturnText, Summary };
