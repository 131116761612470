import { CarouselRenderControl } from 'nuka-carousel';

import { useHooks } from '@everlywell/leaves';

type BeforeSlideType = (index: number, nextIndex: number) => void;

type CarouselSettings = {
  beforeSlide: BeforeSlideType;
  cellAlign: 'left' | 'center' | 'right';
  cellSpacing: number;
  dragging: boolean;
  framePadding: string;
  heightMode: 'first' | 'current' | 'max';
  onDragStart: () => void;
  renderBottomCenterControls: CarouselRenderControl | null;
  renderCenterLeftControls: CarouselRenderControl | null;
  renderCenterRightControls: CarouselRenderControl | null;
  slidesToShow?: number;
  slideWidth: string | number;
};

export type CarouselConfigProps = {
  beforeSlide: BeforeSlideType;
  controls: CarouselRenderControl | null;
  onDragStart: () => void;
  slideCount: number;
};

type MediaQueryOutput = boolean | null;

const getDevice = (
  isTablet: MediaQueryOutput,
  isDesktop: MediaQueryOutput,
): string => {
  if (isTablet) return 'tablet';

  if (isDesktop) return 'desktop';

  return 'mobile';
};

const carouselConfig = (props: CarouselConfigProps): CarouselSettings => {
  const { beforeSlide, controls, slideCount, onDragStart } = props;
  const { isTablet, isDesktop } = useHooks.useWindowSize();
  const device = getDevice(isTablet, isDesktop);

  let settings: CarouselSettings = {
    beforeSlide,
    cellAlign: 'center',
    cellSpacing: 12,
    dragging: slideCount > 1,
    framePadding: '0',
    heightMode: 'max',
    onDragStart,
    renderCenterLeftControls: null,
    renderCenterRightControls: null,
    renderBottomCenterControls: controls,
    slideWidth: '300px',
  };
  if (device === 'tablet') {
    settings = {
      ...settings,
      cellAlign: 'left',
      cellSpacing: 24,
      framePadding: '0',
      onDragStart,
      renderBottomCenterControls: slideCount > 1 ? controls : null,
      slideWidth: '370px',
    };
  } else if (device === 'desktop') {
    settings = {
      ...settings,
      cellAlign: 'left',
      cellSpacing: 24,
      dragging: slideCount > 3,
      framePadding: '0',
      onDragStart,
      renderBottomCenterControls: slideCount > 3 ? controls : null,
      slideWidth: '370px',
    };
  }
  return settings;
};

export default carouselConfig;
