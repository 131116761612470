/**
 * ProductCard
 */

import { useHooks } from '@everlywell/leaves';
import Modal from 'components/Modal';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import React, { useRef, useState } from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { PRODUCT_CARD } from 'utils/constants/dataTest';
import { FOOD_SENSITIVITY_TEST_SLUGS } from 'utils/constants/products';
import { APP_ROOT } from 'utils/constants/urls';
import { sanitize } from 'utils/domHelpers';
import { truncateString } from 'utils/helpers';
import { SuggestedProduct, ProductInfoMap, Marker } from 'utils/types';

import { useCart } from '../Cart';
import * as S from './styles';

export type Props = {
  product: SuggestedProduct;
  productInfoMap: ProductInfoMap;
};

// format currency
const formatter = (numberToFormat: number) =>
  new Intl.NumberFormat('en-US', {
    currency: 'USD',
    minimumFractionDigits: 0,
    style: 'currency',
  }).format(numberToFormat);

const ProductCard = (props: Props): JSX.Element => {
  const { product, productInfoMap } = props;
  const [isOpen, setIsOpen] = useState(false);
  const variant = productInfoMap[product.productId];
  const { isMobile } = useHooks.useWindowSize();
  const productDescCharCount = isMobile ? 100 : 123;
  const addToCartRef = useRef(null);

  const { addItemToCart } = useCart({
    addItemToCart: {
      onSuccess: () => {
        window.location.href = `/checkout`;
        return false;
      },
    },
  });

  const handleAddToCart = () => {
    addItemToCart({
      variant_id: variant.variantId,
      quantity: 1,
    });

    ewAnalytics.track({
      data: {
        label: ANALYTICS.LABELS.ADD_TO_CART,
        'Test ID': product.productId,
        'Test Name': product.title,
      },
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
    });
  };

  // toggle the modal
  const handleToggleModal = (e: any) => {
    e.stopPropagation();

    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.VIEWED_TEST_MARKERS,
        'Test ID': product.productId,
        'Test Name': product.title,
      },
    });
    setIsOpen((currentIsOpen) => {
      const htmlEl = document.getElementsByTagName('html')[0];
      if (currentIsOpen) htmlEl.style.overflowY = '';
      else htmlEl.style.overflowY = 'hidden';
      return !currentIsOpen;
    });
  };

  // track learn more clicks
  const handleLearnMore = (e: any) => {
    e.stopPropagation();
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.LEARN_MORE,
        'Test ID': product.productId,
        'Test Name': product.title,
      },
    });
  };

  const createMarkerString = (product: SuggestedProduct) => {
    if (FOOD_SENSITIVITY_TEST_SLUGS.includes(product.slug))
      return product.measures;

    const list = product.markerList.markers;
    const charCount = isMobile ? 60 : 80;
    const reducedString = list.reduce((acc: string, marker, i: number) => {
      if (i < list.length - 1) {
        return `${acc + marker.title}, `;
      }

      return acc + marker.title;
    }, '');

    return truncateString(reducedString, charCount, ',', ', and more');
  };

  return (
    <S.ProductCard data-testid={PRODUCT_CARD.WRAPPER}>
      <S.ProductImage>
        <Img image={product.pdpImage.gatsbyImageData} alt="" />
      </S.ProductImage>
      <S.ProductTitleSection>
        <S.ProductTitle>{product.displayName}</S.ProductTitle>
        {variant ? (
          <S.ProductPrice>{formatter(Number(variant.price))}</S.ProductPrice>
        ) : null}
      </S.ProductTitleSection>
      <S.ProductDescription>
        {truncateString(product.description, productDescCharCount)}
      </S.ProductDescription>
      <S.MeasuresLabel>Measures</S.MeasuresLabel>
      <S.Measures
        data-testid={PRODUCT_CARD.MEASURES}
        onClick={handleToggleModal}
      >
        {createMarkerString(product)}
        {isOpen ? (
          <Modal onClose={handleToggleModal}>
            <>
              <S.MeasuredTitle>What's Measured?</S.MeasuredTitle>
              {product.markerList.markers.map((marker: Marker, index: number) =>
                marker.description ? (
                  <S.MeasuredContentWrapper key={`${marker.title}-${index}`}>
                    <S.MeasuredMarkerTitle>
                      {marker.title}{' '}
                    </S.MeasuredMarkerTitle>
                    <S.MeasuredMarkerContent
                      dangerouslySetInnerHTML={{
                        __html: sanitize(marker.description.internal.content),
                      }}
                    />
                  </S.MeasuredContentWrapper>
                ) : null,
              )}
            </>
          </Modal>
        ) : null}
      </S.Measures>
      <S.CTAs>
        <S.LearnMore
          onClick={handleLearnMore}
          href={`${APP_ROOT}/products/${product.slug}/`}
        >
          Learn more
        </S.LearnMore>
        <S.AddToCart
          data-testid="add-to-cart"
          ref={addToCartRef}
          onClick={handleAddToCart}
        >
          Add To Cart
        </S.AddToCart>
      </S.CTAs>
    </S.ProductCard>
  );
};

export default ProductCard;
