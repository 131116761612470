import FunFact from 'components/FunFact';
import RichTextWrapper from 'components/RichTextWrapper';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import React from 'react';
import { SectionType } from 'utils/types';

import * as S from './styles';

type Props = {
  section: SectionType;
};

const InfoSection = (props: Props): JSX.Element => {
  const {
    section: { title, image, subtitle, content, funFact, backgroundColor },
  } = props;

  const isReferencesSection = title === 'References';

  return (
    <S.Section data-testid="info-section" key={title} bgColor={backgroundColor}>
      {image ? (
        <S.ImageWrap>
          <Img image={image.gatsbyImageData} alt={title || ''} />
        </S.ImageWrap>
      ) : null}
      <S.TextWrapper>
        {title ? (
          <S.Title isReferencesSection={isReferencesSection}>{title}</S.Title>
        ) : null}
        {subtitle ? (
          <S.Subtitle>
            <RichTextWrapper contentfulRichText={subtitle} />
          </S.Subtitle>
        ) : null}
        {content ? (
          <S.Content isReferencesSection={isReferencesSection}>
            <RichTextWrapper contentfulRichText={content} />
          </S.Content>
        ) : null}
        {funFact ? <FunFact {...funFact} /> : null}
      </S.TextWrapper>
    </S.Section>
  );
};

export default InfoSection;
