import React from 'react';
import * as S from './styles';
import { DOTS } from 'utils/constants/dataTest';
import { generateKey } from 'utils/helpers';

export type DotProps = {
  cardCount: number;
  slidesToScroll: number;
  currentSlide: number;
  goToSlide: Function;
};

const Dots = ({
  cardCount,
  slidesToScroll,
  currentSlide,
  goToSlide,
}: DotProps) => {
  const getIndexes = (count: number, inc: number) => {
    const arr = [];
    for (let i = 0; i < count; i += inc) {
      arr.push(i);
    }

    return arr;
  };

  const indexes = getIndexes(cardCount, slidesToScroll);

  return (
    <ul>
      {indexes.map((index: number) => (
        <S.ListItemStyles data-testid={DOTS.WRAPPER} key={generateKey()}>
          <S.DotButton
            active={currentSlide === index}
            data-testid={DOTS.BUTTON}
            onClick={() => {
              goToSlide(index);
            }}
          />
        </S.ListItemStyles>
      ))}
    </ul>
  );
};

export default Dots;
