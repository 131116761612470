import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import Intro from 'components/Intro';
import Section from 'components/Section';
import Summary from 'components/Summary';
import TrustCard from 'components/TrustCard';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { PageContext, ProductInfoMap, SectionType } from 'utils/types';
import {
  useProductVariants,
  useProductBySlug,
  useProductInfoMap,
} from 'utils/hooks';

import Layout from '../components/Layout';
import { SEO } from '../components/SEO';

type Props = {
  data: any;
  location: {
    state?: {
      comingFromQuiz?: boolean;
      symptomOrCategory?: string;
    };
  };
  pageContext: PageContext & { pageSlug: string };
};

const LandingTemplate = (props: Props): JSX.Element => {
  const { location, pageContext } = props;
  const { nodeIndex, seoPage, subtitle, title, description } = pageContext;
  const productVariants = useProductVariants();
  const { allContentfulLandingPage, productInfoMap } =
    useProductInfoMap(productVariants);
  const productsBySlug = useProductBySlug(productVariants);
  const plainTextTitle = documentToPlainTextString(pageContext.title.json);

  useEffect(() => {
    const pageState = location.state || {};

    // track landing page view
    ewAnalytics.track({
      data: {
        page: plainTextTitle,
        'symptom or category': pageState.symptomOrCategory,
        'took quiz': !!pageState.comingFromQuiz,
      },
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
    });
  }, []);

  const currentPage = allContentfulLandingPage.nodes[nodeIndex];

  const { landingPageContent: sections, summary, trustCard } = currentPage;

  return (
    <>
      <SEO faqSchema={currentPage.faqSchema} />
      <Helmet>
        {description && <meta name="description" content={description} />}
        <title>{plainTextTitle}</title>
      </Helmet>
      <Layout productsBySlug={productsBySlug}>
        <Intro title={title} subtitle={subtitle} seoPage={seoPage} />
        {trustCard ? <TrustCard seoPage={seoPage} {...trustCard} /> : null}
        {sections ? (
          <div>
            {sections.map((section: SectionType, index: number) => (
              <Section
                key={index}
                section={section as SectionType}
                productInfoMap={productInfoMap as ProductInfoMap}
              />
            ))}
          </div>
        ) : null}
        <div>{summary ? <Summary summary={summary} /> : null}</div>
      </Layout>
    </>
  );
};

export default LandingTemplate;
