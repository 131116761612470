import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import { DynamicSubtitle, DynamicTitle } from 'utils/types';
import { INTRO } from 'utils/constants/dataTest';
import { sanitize } from 'utils/domHelpers';
import * as S from './styles';

type Props = {
  title: DynamicTitle;
  subtitle: DynamicSubtitle;
  seoPage: boolean;
};

const Intro = (props: Props) => {
  const { seoPage, subtitle, title } = props;

  const formattedSubtitle = unescape(documentToPlainTextString(subtitle.json));

  return (
    <S.Intro data-testid={INTRO.WRAPPER} seoPage={seoPage}>
      <S.Title>{documentToReactComponents(title.json)}</S.Title>
      <S.Subtitle
        data-testid="intro-subtitle"
        seoPage={seoPage}
        dangerouslySetInnerHTML={{ __html: sanitize(formattedSubtitle) }}
      />
    </S.Intro>
  );
};

export default Intro;
