import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import React from 'react';
import { FUN_FACT } from 'utils/constants/dataTest';
import { AdditionalInfoType } from 'utils/types';

import * as S from './styles';

const FunFact = (props: AdditionalInfoType): JSX.Element => {
  const { content, icon, linkUrl, linkText } = props;

  return (
    <S.Wrapper data-testid={FUN_FACT.WRAPPER}>
      <S.IconWrapper>
        <Img image={icon.gatsbyImageData} alt={linkText || ''} />
      </S.IconWrapper>
      <S.ContentWrapper>
        {content ? (
          <S.Content>{documentToReactComponents(content.json)}</S.Content>
        ) : null}
        <S.Link data-testid={FUN_FACT.LINK} href={linkUrl}>
          {linkText}
        </S.Link>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default FunFact;
