import { typography } from '@everlywell/leaves';
import styled from 'styled-components';

interface SeoProps {
  seoPage: boolean;
}

const Intro = styled.div<SeoProps>`
  padding: ${({ seoPage }) => (seoPage ? '24px' : '24px 32px')};
  text-align: center;

  ${(mediaQuery) => mediaQuery.theme.forTabletVerticalUp} {
    padding: 64px 24px 48px;
  }

  ${(mediaQuery) => mediaQuery.theme.forTabletHorizontalUp} {
    padding: 48px 112px 24px;
  }

  ${(mediaQuery) => mediaQuery.theme.forDesktopUp} {
    padding: 48px 300px 24px;
  }
`;

const Title = styled.h1`
  font-weight: ${typography.weight.bold};

  p {
    color: rgb(${({ theme }) => theme.primaryTextColor});
  }

  u {
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: rgb(${({ theme }) => theme.quinaryTextColor});
    font-weight: ${typography.weight.bold};
  }

  ${(mediaQuery) => mediaQuery.theme.forTabletVerticalUp} {
    font-size: 41px;
    line-height: 1.36;

    u {
      &::after {
        bottom: -6px;
        background: rgb(${({ theme }) => theme.quinaryTextColor});
        height: 8px;
      }
    }
  }
`;

const Subtitle = styled.h2<SeoProps>`
  font-size: 16px;
  font-weight: ${typography.weight.light};
  line-height: 1.78;
  margin-top: ${({ seoPage }) => (seoPage ? '14px' : '12px')};
  padding: 0 8px;

  ${(mediaQuery) => mediaQuery.theme.forTabletVerticalUp} {
    font-size: 18px;
    line-height: 1.78;
    margin-top: 24px;
  }

  ${(mediaQuery) => mediaQuery.theme.forTabletHorizontalUp} {
    padding: 0 40px;
  }
`;

export { Intro, Subtitle, Title };
